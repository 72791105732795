body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
hr {
  opacity: 0.2;
}

.selected-slot {
  background-color: #bc9b5d;
}

.other-menu {
  background-color: #3bb168;
}

.order-total {
  padding: 8px;
}

.order-total .sub-total,
.order-total .total {
  display: flex;
  justify-content: space-between;
}

.order-total .sub-total span,
.order-total .total span {
  text-transform: capitalize;
}

.order-total .sub-total .sub-total-amt,
.order-total .sub-total .total-amt,
.order-total .total .sub-total-amt,
.order-total .total .total-amt {
  font-weight: bold;
  margin-bottom: 4px;
}

.order-total .address .add-details {
  max-width: 70%;
  text-align: right;
}

.im-menu {
  color: #000;
  font-size: 32px;
  margin-right: 8px;
}

.nav-bar {
  background: none;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-bar .settings-bar {
  width: 10%;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  padding: 0.5rem;
  margin: 0 24px 0 0;
  border-radius: 0.3em;
  /* box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 1px; */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.nav-bar button {
  background: none;
  border: none;
}

.nav-bar .active-page {
  border-bottom: 2px solid #263238;
  padding: 8px;
}

.nav-bar a {
  text-decoration: none;
  padding: 8px;
  margin-right: 8px;
  border-bottom: 2px solid #fff;
  color: #1c222d;
  font-weight: bold;
  font-size: 20px;
}

.date-time {
  text-transform: uppercase !important;
  background-color: #fafafa;
  border-radius: 0.3em;
  padding: 8px;
  font-weight: bold;
}

.address {
  margin-top: 16px;
  margin-bottom: 16px;
}

.sections {
  -webkit-padding-start: 0px !important;
          padding-inline-start: 0px !important;
  list-style: none;
}

.sections li {
  margin-bottom: 1em;
}

.sections li a {
  color: #bc9b5d;
  font-weight: bold;
}

.App {
  min-height: 100vh;
  background-color: #fafafa;
}

.App #mains #current,
.App #mains #archived {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  padding: 24px;
}

.App #mains #current .order-sum,
.App #mains #archived .order-sum {
  text-transform: capitalize;
  padding: 16px;
  border-radius: 0.3em;
  color: #263238;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.App #mains #current .order-sum .cart-items,
.App #mains #archived .order-sum .cart-items {
  background-color: #eceff1;
  padding: 8px;
  border-radius: 0.3em;
}

.App #mains #current .order-sum .order-item .item-details,
.App #mains #archived .order-sum .order-item .item-details {
  display: flex;
  justify-content: space-between;
}

.App #mains #current .order-sum .order-item .item-details .item-name,
.App #mains #archived .order-sum .order-item .item-details .item-name {
  font-weight: bold;
}

.App #mains #current .order-sum .order-item .item-qty,
.App #mains #archived .order-sum .order-item .item-qty {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App #mains #current .order-sum .order-item .item-qty h5,
.App #mains #archived .order-sum .order-item .item-qty h5 {
  margin: 4px;
}

.App #mains #current .order-sum .order-item .item-qty .edit-qty,
.App #mains #archived .order-sum .order-item .item-qty .edit-qty {
  background: none;
  border: none;
  color: #000;
}

.App #mains .menu-page {
  padding: 0px 2em 2em 2em;
  display: flex;
}

.App #mains .menu-page button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.App #mains .menu-page .menu-page-col-1,
.App #mains .menu-page .menu-page-col-2 {
  flex: 1 1;
}

.App #mains .menu-page .menu-page-col-1 button {
  border: none;
  color: #bc9b5d;
  background: none;
  border-radius: 0.3em;
  padding: 8px;
  font-weight: bold;
}

.App #mains .menu-page .menu-page-col-1 button svg {
  margin-right: 4px;
}

.App #mains .menu-page .menu-page-col-1 h2 {
  font-size: 3em;
  text-transform: uppercase;
  font-weight: bold;
}

.App #mains .menu-page .menu-page-col-1 .menu-timings,
.App #mains .menu-page .menu-page-col-1 .categories {
  display: flex;
  margin-bottom: 1em;
}

.App #mains .menu-page .menu-page-col-1 .menu-timings span,
.App #mains .menu-page .menu-page-col-1 .categories span {
  background-color: #eceff1;
  font-weight: bold;
  font-size: 1.2em;
  padding: 0.5em;
  border-radius: 0.5em;
  margin-right: 1em;
  text-transform: capitalize;
}

.App #mains .menu-page .menu-page-col-1 .menu-item-list {
  overflow-y: scroll;
  max-height: 68vh;
}

.App #mains .menu-page .menu-page-col-1 .list-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.App #mains .menu-page .menu-page-col-1 .list-bar button {
  border: none;
  color: #bc9b5d;
  background: none;
  border-radius: 0.3em;
  padding: 8px;
}

.App #mains .menu-page .menu-page-col-1 .list-bar button svg {
  margin-right: 4px;
  color: #bc9b5d;
}

.App #mains .menu-page .menu-page-col-1 .list-bar .search-bar {
  display: flex;
  justify-content: flex-end;
}

.App #mains .menu-page .menu-page-col-1 .list-bar .search-bar label {
  font-weight: bold;
}

.App #mains .menu-page .menu-page-col-1 .list-bar .search-bar svg {
  margin-right: 4px;
  color: #bc9b5d;
}

.App #mains .menu-page .menu-page-col-1 .list-bar .search-bar input {
  border: none;
  background-color: #eceff1;
  padding: 4px;
  border-radius: 0.3em;
}

.App #mains .menu-page .menu-page-col-1 .search-bar {
  display: flex;
  justify-content: flex-end;
}

.App #mains .menu-page .menu-page-col-1 .search-bar label {
  font-weight: bold;
}

.App #mains .menu-page .menu-page-col-1 .search-bar input {
  border: none;
  background-color: #eceff1;
  padding: 4px;
  border-radius: 0.3em;
}

.App #mains .menu-page .menu-page-col-1 .menu-item {
  direction: ltr;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 3em;
  background-color: #fff;
  align-items: center;
  margin: 0px 16px 16px 16px;
  border-radius: 0.3em;
  box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 1px;
}

.App #mains .menu-page .menu-page-col-1 .menu-item img {
  height: auto;
  width: 50%;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.App #mains .menu-page .menu-page-col-1 .menu-item a {
  background-color: #ffa938;
  color: #fff;
  padding: 4px;
  border-radius: 0.3em;
  text-align: center;
  font-weight: bold;
}

.App #mains .menu-page .menu-page-col-1 .menu-item h4 {
  text-align: left;
  font-size: 1.2em;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
  padding: 16px 0px 16px 0px;
}

.App #mains .menu-page .menu-page-col-1 .menu-item button {
  height: 48px;
}

.App #mains .menu-page .menu-page-col-1 .menu-item svg {
  cursor: pointer;
}

.App #mains .menu-page .menu-page-col-1 .menu-item svg:hover {
  color: #bc9b5d;
  transition: 0.2s;
}

.App #mains .menu-page .menu-page-col-2 {
  display: flex;
  justify-content: center;
}

.App #mains .menu-page .menu-page-col-2 .editbox {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  padding: 2em;
  border-radius: 0.5em;
  height: 100%;
  width: 90%;
}

.App #mains .menu-page .menu-page-col-2 .editbox #timings .timing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App #mains .menu-page .menu-page-col-2 .editbox #timings .timing-header h3 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 0;
}

.App #mains .menu-page .menu-page-col-2 .editbox #timings .timing-header button {
  border: none;
  color: #47e689;
  background: none;
  border-radius: 0.3em;
  padding: 8px;
  font-weight: bold;
}

.App #mains .menu-page .menu-page-col-2 .editbox #timings .timing-header button svg {
  margin-right: 4px;
}

.App #mains .menu-page .menu-page-col-2 .editbox #timings .timeslots {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}

.App #mains .menu-page .menu-page-col-2 .editbox #timings .timeslots button {
  border: none;
  padding: 1em;
  border-radius: 0.3em;
  font-weight: bold;
  cursor: pointer;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form {
  width: 100%;
  height: 100%;
  border-radius: 0.3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-details,
.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-settings {
  width: 48%;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-details .item-options .item-options-list div,
.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-settings .item-options .item-options-list div {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background-color: #eceff1;
  margin-bottom: 1em;
  border-radius: 0.5em;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-details .item-options .item-options-list div span,
.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-settings .item-options .item-options-list div span {
  font-weight: bold;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-details .item-options .item-options-list div svg:hover,
.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-settings .item-options .item-options-list div svg:hover {
  color: #bc9b5d;
  transition: 0.2s;
  cursor: pointer;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-details .item-options .options-group .choices label,
.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-settings .item-options .options-group .choices label {
  font-weight: bold;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-details .item-options .options-group .choices .add-choice,
.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-settings .item-options .options-group .choices .add-choice {
  display: flex;
  flex-direction: row;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-details .item-options .options-group .choices .add-choice input,
.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-settings .item-options .options-group .choices .add-choice input {
  flex-grow: 1;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-details .item-options .options-group .choices .choice-item,
.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .item-info .item-settings .item-options .options-group .choices .choice-item {
  background-color: #eceff1;
  width: 100%;
  padding: 1em;
  border-radius: 0.3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  font-weight: bold;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form img {
  width: 100%;
  border-radius: 0.3em;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form button {
  border: none;
  color: #bc9b5d;
  background: none;
  border-radius: 0.3em;
  padding: 8px;
  font-weight: bold;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form button svg {
  margin-right: 4px;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1em 0 1em 0;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .buttons .save-btn {
  color: #3bb168;
  border: 2px solid #3bb168;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .form-group .file-form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .form-group input,
.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .form-group textarea {
  border: none;
  background-color: #eceff1;
  border-radius: 0.3em;
  padding: 8px;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .form-group select {
  padding: 8px;
  border: none;
  background-color: #eceff1;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .form-group label {
  font-weight: bold;
  color: #38262a;
}

.App #mains .menu-page .menu-page-col-2 .editbox .edit-box .item-form .form-group textarea {
  height: 140px;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box {
  display: flex;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list,
.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-items {
  flex: 1 1;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list h1 span,
.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-items h1 span {
  font-weight: bold;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list .save-btn {
  border: none;
  background: none;
  color: #47e689;
  margin-top: 8px;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list .save-btn svg {
  margin-right: 4px;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list #new-cat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1em;
  padding-top: 0px;
  align-items: center;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list #new-cat input {
  padding: 1em;
  width: 70%;
  border: none;
  background-color: #eceff1;
  border-radius: 0.3em;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list .new-cat-btn {
  color: #bc9b5d;
  border: none;
  background: none;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list .category-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list .category-actions button {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list .category-actions .save-btn {
  border: none;
  background: none;
  color: #47e689;
  margin-top: 8px;
  width: 100px;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list .category-actions .save-btn svg {
  margin-right: 4px;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list .categories-list {
  display: flex;
  flex-direction: column;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list .categories-list .cat-name {
  font-size: 1.2em;
  display: flex;
  justify-content: space-between;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list .categories-list .cat-name:hover {
  background-color: #bc9b5d;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list span,
.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list button {
  font-weight: bold;
  padding: 1em;
  margin-bottom: 1em;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list span {
  background-color: #eceff1;
  border-radius: 0.3em;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list .cat-active {
  background-color: #bc9b5d;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-list button {
  background-color: #dddfe0;
  margin: 0;
  border-radius: 0.3em;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-items {
  padding: 1em;
  padding-top: 0px;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-items h1 {
  display: flex;
  justify-content: space-between;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-items h1 button {
  font-size: 0.5em;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-items .category-item-list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 80vh;
}

.App #mains .menu-page .menu-page-col-2 .editbox #categories-box .category-items .category-item-list span {
  background-color: #eceff1;
  border-radius: 0.3em;
  font-weight: bold;
  padding: 1em;
  margin-bottom: 1em;
}

.App #mains #menus {
  padding: 32px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.App #mains #menus .menu-link {
  font-weight: bold;
  padding: 2em;
  margin: 1em;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  background-color: #fff;
  color: #1c222d;
  font-size: 2em;
  border-radius: 0.3em;
  align-self: center;
}

.App #mains #menus .menu-link a {
  color: #bc9b5d;
}

.App #mains #menus .create-new-menu {
  background-color: #bc9b5d;
}

.App #mains #menus .create-new-menu a {
  color: #fff;
}

.App #mains #menus .createMenu .form-group {
  display: flex;
  flex-direction: column;
}

.App #mains #menus .createMenu .form-group label {
  font-size: 1.2rem;
  color: #fff;
}

.App #mains #menus .createMenu .form-group input {
  border: none;
  background-color: #fff;
  border-radius: 0.3em;
  font-size: 1.5rem;
  padding: 0.5rem;
}

.App #mains #menus .createMenu button {
  font-size: 1rem;
  font-weight: bold;
  border: 2px solid #fff;
  border-radius: 0.3em;
  background: #fff;
  color: #bc9b5d;
  cursor: pointer;
}

.App #mains #menus #menu-items h1,
.App #mains #menus #edit h1 {
  font-weight: bold;
  margin-top: 16px;
}

.App #mains #menus #menu-items {
  overflow-y: scroll;
  height: 100vh;
  direction: rtl;
}

.App #mains #menus #menu-items .menu-header {
  display: flex;
  direction: ltr;
  justify-content: space-between;
}

.App #mains #menus #menu-items h1 {
  direction: ltr;
  margin: 16px;
}

.App #mains #menus #menu-items .menu-item {
  direction: ltr;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  background-color: #fff;
  margin: 16px;
  padding: 16px;
  border-radius: 0.3em;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.App #mains #menus #menu-items .menu-item a {
  background-color: #ffa938;
  color: #fff;
  padding: 4px;
  border-radius: 0.3em;
  text-align: center;
  font-weight: bold;
}

.App #mains #menus #menu-items .menu-item h4 {
  text-align: center;
}

.App #mains #menus #menu-items .menu-item button {
  height: 48px;
}

.timing-setting-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timing-setting-page .timeslot-duration {
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 0.5rem 0;
}

.timing-setting-page .opening-hours h2 {
  font-size: 2rem;
  font-weight: bold;
}

.timing-setting-page .opening-hours .opening-hours-box {
  background-color: #fff;
  margin: 0.5rem;
  padding: 0.75rem;
}

.timing-setting-page .opening-hours .opening-hours-box button {
  margin-top: 0.5rem;
}

.timing-setting-page .opening-hours .hour-start,
.timing-setting-page .opening-hours .hour-end {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0;
}

.profiles-page {
  display: flex;
  padding: 1rem;
}

.profiles-page .profile-info,
.profiles-page #timings {
  flex: 1 1;
  padding: 1rem 1rem;
  margin: 0 1rem;
  background-color: #fff;
  border-radius: 0.3em;
  box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 1px;
}

.profiles-page #timings .timing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.profiles-page #timings .timing-header h3 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 0;
}

.profiles-page #timings .timing-header button {
  border: none;
  color: #47e689;
  background: none;
  border-radius: 0.3em;
  padding: 8px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profiles-page #timings .timing-header button svg {
  margin-right: 4px;
}

.profiles-page #timings .timeslots {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}

.profiles-page #timings .timeslots button {
  border: none;
  padding: 1em;
  border-radius: 0.3em;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 1px;
  cursor: pointer;
}

.profiles-page .profile-info {
  padding: 1rem;
}

.profiles-page .profile-info .profile-selector-box .profile-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #bc9b5d;
}

.profiles-page .profile-info .profile-selector-box .profile-page-header button {
  border: none;
  background: none;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profiles-page .profile-info .profile-selector-box .profile-page-header h2 {
  margin-bottom: 0;
}

.profiles-page .profile-info .profile-selector-box .profile-list {
  margin: 0.5rem 0;
}

.profiles-page .profile-info .profile-selector-box .profile-list button {
  background-color: #fafafa;
  border: none;
  cursor: pointer;
  border-left: 8px solid;
  font-size: 1.5em;
  margin: 0.5rem;
  padding: 0.2em 0.4em;
  box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 1px;
}

.profiles-page .profile-info .profile-info-box .new-profile-submit {
  border: none;
  background: none;
  color: #3bb168;
  font-weight: bold;
}

.profiles-page .profile-info .profile-info-box .profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profiles-page .profile-info .profile-info-box .profile-header .profile-name {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.profiles-page .profile-info .profile-info-box .profile-header .profile-name button {
  border: none;
  padding: 0.25rem;
  font-weight: bold;
}

.profiles-page .profile-info .profile-info-box .profile-header .profile-name span {
  font-size: 2rem;
}

.profiles-page .profile-info .profile-info-box .profile-header .profile-name input {
  border: none;
  padding: 0.5rem;
  background-color: #dddfe0;
  font-size: 1.2em;
}

.profiles-page .profile-info .profile-info-box .profile-header .color-selected-box {
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 0.5rem;
}

.profiles-page .profile-info .profile-info-box .profile-menus {
  display: flex;
  padding-top: 1em;
}

.profiles-page .profile-info .profile-info-box .profile-menus div {
  flex: 1 1;
}

.profiles-page .profile-info .profile-info-box .profile-menus .selected-menus,
.profiles-page .profile-info .profile-info-box .profile-menus .avail-menus {
  display: flex;
  flex-direction: column;
}

.profiles-page .profile-info .profile-info-box .profile-menus .selected-menus button,
.profiles-page .profile-info .profile-info-box .profile-menus .avail-menus button {
  background: none;
  width: 200px;
  margin-bottom: 1rem;
  border-style: solid;
  border-width: 3px;
  border-radius: 0.3em;
  font-weight: bold;
  padding: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
}

.profiles-page .profile-info .profile-info-box .profile-menus .selected-menus .active-menu-in-list,
.profiles-page .profile-info .profile-info-box .profile-menus .avail-menus .active-menu-in-list {
  border-color: #bc9b5d;
}

.create-profile .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.create-profile .form-group input {
  width: 100%;
  background: #dddfe0;
  border: none;
  border-radius: 0.3em;
}

.create-profile .form-group label {
  font-weight: bold;
}

.create-profile button {
  border: none;
  background: none;
  font-weight: bold;
  color: #bc9b5d;
  margin-top: 0.5rem;
  cursor: pointer;
}

.store-settings-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.store-settings-page span {
  margin-bottom: 0.5rem;
}

.nav-headers {
  font-weight: 400 !important;
}

.assets-page {
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.assets-page .landing-banners,
.assets-page .carousel-banners {
  border-radius: 0.3em;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 1px;
  margin-bottom: 1rem;
  padding: 1rem;
}

.assets-page .landing-banners h3,
.assets-page .carousel-banners h3 {
  font-size: 1.5em;
}

.assets-page .landing-banners .banner-previews {
  display: flex;
}

.assets-page .landing-banners .banner-previews .banner-preview-img {
  border-radius: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.assets-page .landing-banners .banner-previews .desktop-landing-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2 1;
  padding: 1em;
}

.assets-page .landing-banners .banner-previews .desktop-landing-preview .banner-preview-img {
  height: auto;
  width: 100%;
}

.assets-page .landing-banners .banner-previews .mobile-landing-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  padding: 1em;
}

.assets-page .landing-banners .banner-previews .mobile-landing-preview .banner-preview-img {
  width: auto;
  height: 500px;
}

.assets-page .carousel-banners .carousel-banner-images {
  display: flex;
}

.assets-page .carousel-banners .carousel-banners-list,
.assets-page .carousel-banners .carousel-active {
  flex: 1 1;
}

.assets-page .carousel-banners .carousel-banners-list .banner-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1em;
  grid-gap: 1em;
}

.assets-page .carousel-banners .carousel-banners-list .banner-list .banner-container {
  position: relative;
}

.assets-page .carousel-banners .carousel-banners-list .banner-list .banner-container .delete-banner {
  position: absolute;
  border: none;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0.5em;
  color: #fff;
  top: 0;
  right: 0;
}

.assets-page .carousel-banners .carousel-banners-list .banner-list img {
  height: auto;
  width: 100%;
  border-radius: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.assets-page .carousel-banners .carousel-active {
  padding: 1em;
}

.assets-page .carousel-banners .carousel-active img {
  height: auto;
  width: 100%;
  border-radius: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  margin-bottom: 1em;
}

.banner-upload-actions {
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.banner-upload-actions .file-form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  padding: 1em;
  border-radius: 0.3em;
}

.banner-upload-actions .file-form-group button {
  border: none;
  display: flex;
  align-items: center;
  padding: 0.5em;
}

input[type="file"] button {
  border: none;
  background: #bc9b5d;
}

.name-inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.name-inputs label {
  font-weight: bold;
}

.name-inputs .input-group {
  display: flex;
}

.name-inputs .input-group input {
  border: none;
  background-color: #eceff1;
  padding: 0.5em;
}

.name-inputs .input-group button {
  border: none;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.login form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login form .form-group {
  display: flex;
  flex-direction: column;
}

.login form .form-group input {
  border: none;
  padding: 0.5em;
  background-color: #eceff1;
  border-radius: 0.5em;
}

.login form .form-group label {
  font-weight: bold;
}

.login form button {
  margin-top: 1em;
  background-color: #bc9b5d;
  color: #fff;
  border: none;
  padding: 0.5em;
  width: 100%;
  font-weight: bold;
  border-radius: 0.5em;
}

h1 #catNameEdit {
  border: none;
  background-color: #eceff1;
}

h1 button {
  border: none;
  font-weight: bold;
}

#logout-button {
  position: fixed;
  bottom: 1em;
  left: 1em;
}
/*# sourceMappingURL=App.css.map */
